import { Permission } from "../../shared/types/user";

export function generateRandomPassword() {
  const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const lowercase = "abcdefghijklmnopqrstuvwxyz";
  const numbers = "0123456789";
  const specialChars = "!@#$%^&*()-_=+[]{}|;:,.<>?";
  const allChars = uppercase + lowercase + numbers + specialChars;

  function getRandomChar(charSet: string) {
    return charSet[Math.floor(Math.random() * charSet.length)];
  }

  // Ensure at least one of each required character type
  const randomPassword = [
    getRandomChar(uppercase),
    getRandomChar(lowercase),
    getRandomChar(numbers),
    getRandomChar(specialChars),
  ];

  for (let i = randomPassword.length; i < 10; i++) {
    randomPassword.push(getRandomChar(allChars));
  }

  for (let i = randomPassword.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [randomPassword[i], randomPassword[j]] = [
      randomPassword[j],
      randomPassword[i],
    ];
  }

  return randomPassword.join("");
}

export function isValidPhoneNumber(phone: string) {
  // Regular expression for E.164 format: + followed by 1 to 3 digits (country code) and 7 to 15 digits (subscriber number)
  const e164Regex = /^\+\d{1,3}\d{7,12}$/;
  return e164Regex.test(phone);
}

export function getRoleByPermissions(permissions: Partial<Permission>): string {
  const roles = {
    "super-admin": {
      canDeleteSection: true,
      canEditSection: true,
      canEditSectionFiles: true,
      canEditSectionLinks: true,
      canEditUsers: true,
    },
    documenter: {
      canDeleteSection: true,
      canEditSection: true,
      canEditSectionFiles: true,
      canEditSectionLinks: true,
      canEditUsers: false,
    },
    admin: {
      canDeleteSection: false,
      canEditSection: false,
      canEditSectionFiles: false,
      canEditSectionLinks: false,
      canEditUsers: true,
    },
    default: {
      canDeleteSection: false,
      canEditSection: false,
      canEditSectionFiles: false,
      canEditSectionLinks: false,
      canEditUsers: false,
    },
  };

  for (const [role, rolePermissions] of Object.entries(roles)) {
    if (isEqualPermissions(rolePermissions, permissions)) {
      return role;
    }
  }

  return "reader";
}

function isEqualPermissions(
  rolePermissions: Partial<Permission>,
  permissions: Partial<Permission>
): boolean {
  return (
    rolePermissions.canDeleteSection === permissions.canDeleteSection &&
    rolePermissions.canEditSection === permissions.canEditSection &&
    rolePermissions.canEditSectionFiles === permissions.canEditSectionFiles &&
    rolePermissions.canEditSectionLinks === permissions.canEditSectionLinks &&
    rolePermissions.canEditUsers === permissions.canEditUsers
  );
}
