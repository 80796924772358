import React, { useState } from "react";
import CustomButton from "../../shared/components/general/custom-button";
import { TrashIcon } from "@heroicons/react/24/outline";
import { SectionFile, Section } from "../../shared/types/section";
import apiService from "../../services/api-service";
import toast from "react-hot-toast";
import { getFileLogoByFileName } from "../utils/file-utils";
import { LoadingFile } from "./LoadingSection";
import { useAuthContext } from "../../context/AuthContext";

type Props = {
  section: Section;
  file: SectionFile;
  onDelete: () => void;
  onEditClick: () => void;
};

function SectionFileContainer({ file, section, onDelete, onEditClick }: Props) {
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const deleteFile = async () => {
    setLoadingDelete(true);
    const { error } = await apiService.delete(
      `sections/${section.id}/files/${file.id}`
    );
    if (error) {
      toast.error("Error al eliminar el archivo");
      setLoadingDelete(false);
      return;
    }
    setLoadingDelete(false);
    onDelete();
    toast.success("Archivo eliminado correctamente");
  };

  async function getPresignedUrl() {
    setLoading(true);
    // fetch presigned url
    const { data, error } = await apiService.get(
      `sections/${section.id}/fileSignedUrl/${file.id}`
    );

    if (error) {
      toast.error("Error al obtener el archivo");
      setLoading(false);
      return;
    }

    window.open(data, "_blank");
    setLoading(false);
  }

  if (loadingDelete) {
    return <LoadingFile />;
  }

  return (
    <div className="relative bg-gray-200   max-h-80 w-60 flex flex-col space-y-5  rounded-md py-6 px-4 ml-6 text-center flex-shrink-0">
      {user?.permission.canEditSectionFiles && (
        <TrashIcon
          className="h-4 w-4 text-red-500 absolute right-5 top-5 cursor-pointer"
          onClick={deleteFile}
        />
      )}

      <p className="text-base  max-w-80 truncate	 ">{file.fileName}</p>
      <img
        src={getFileLogoByFileName(file.fileName)}
        alt="altan-redes"
        className="w-20 h-24 mx-auto object-fit"
      />
      <CustomButton
        title="Conoce Mas"
        onClick={getPresignedUrl}
        disabled={loading}
        className={loading ? "cursor-progress" : ""}
      />
      {user?.permission.canEditSectionFiles && (
        <CustomButton title="Editar" filled={false} onClick={onEditClick} />
      )}
    </div>
  );
}

export default SectionFileContainer;
