import { Outlet, Navigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { Permission } from "../shared/types/user";

interface AuthValidatorI {
  href: string;
}

function canAccessAdmin(permissions: Permission) {
  return permissions.canEditUsers;
}

export default function AuthValidator({ href }: AuthValidatorI) {
  const { user } = useAuthContext();

  // If there is no user, redirect to login
  if (!user) return <Navigate to="/" />;

  if (href === "/administracion" && !canAccessAdmin(user.permission))
    return <Navigate to="/home" />;

  // Permitir renderizar la ruta
  return <Outlet />;
}
