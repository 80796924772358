import DashboardLayout from "../../shared/components/layout/dashboard-layout";
import NewSectionBox from "./NewSectionBox";
import { SectionContainer } from "./SectionContainer";
import { useBasePageContext } from "../../context/BasePageContext";
import { LoadingSection } from "./LoadingSection";
import { useAuthContext } from "../../context/AuthContext";
import DraggableList from "./DragAndDrop";
import { useState } from "react";
import CustomButton from "../../shared/components/general/custom-button";

type Props = {
  page: string;
};

function BasePage({ page }: Props) {
  const { sections, error, loading, onSectionCreated } = useBasePageContext();
  const { user } = useAuthContext();
  const [isOrderDialogOpen, setIsOrderDialogOpen] = useState(false);

  const isEmpty = !sections || sections?.length === 0;

  function Content() {
    if (loading) {
      return [1, 2].map((i) => <LoadingSection key={i} />);
    }

    if (error) {
      return <p>Error contacta al administrador</p>;
    }

    return (
      <div className="flex flex-col">
        {user?.permission.canEditSection &&
          !loading &&
          !error &&
          sections &&
          sections?.length > 1 && (
            <>
              <CustomButton
                title="Organizar secciones"
                className="mt-5 self-end"
                onClick={() => setIsOrderDialogOpen(true)}
                filled={false}
              />
              <DraggableList
                sections={sections!}
                open={isOrderDialogOpen}
                setIsOpen={setIsOrderDialogOpen}
              />
            </>
          )}

        {sections?.map((section) => (
          <SectionContainer key={section.id} section={section} />
        ))}
      </div>
    );
  }

  return (
    <DashboardLayout>
      {/* <SearchBar /> */}
      <div className="mb-20 space-y-20">
        <Content />
        {user?.permission.canEditSection && !loading && !error && (
          <NewSectionBox
            isEmpty={isEmpty}
            pageName={page}
            sectionCounts={sections?.length!}
            onSectionCreated={onSectionCreated}
          />
        )}
      </div>
    </DashboardLayout>
  );
}

export default BasePage;
