import { useEffect, useState } from "react";
import UserDrawer from "./components/user-drawer";
import UsersTable from "./components/users-table";
import User from "../shared/types/user";
import CustomButton from "../shared/components/general/custom-button";
import apiService from "../services/api-service";
import toast from "react-hot-toast";
import UsersTablePlaceholder from "./components/loading-table";

// TODO: ADD PAGINATION

function UsersPage() {
  const [userToEdit, setUserToEdit] = useState<User | null>(null);
  const [users, setUsers] = useState<User[] | null | undefined>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchUsers() {
      setLoading(true);
      const { data, error } = await apiService.get("users", { page: 1 });
      if (error) {
        setLoading(false);
        toast.error("Error al cargar los usuarios");
        return;
      }
      setUsers(data);
      setLoading(false);
    }
    fetchUsers();
  }, []);

  return (
    <>
      <div className="">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Gestion De Usuarios
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Aquí puedes ver agergar y editar los usuarios de la plataforma.
              Asi como gestionar los permisos de cada uno.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <CustomButton
              title="Agregar usuario"
              onClick={() => {
                setUserToEdit(null);
                setDrawerOpen(true);
              }}
            />
          </div>
        </div>
        {loading ? (
          <UsersTablePlaceholder />
        ) : (
          <UsersTable
            onUserClick={(user) => {
              setUserToEdit(user);
              setDrawerOpen(true);
            }}
            users={users || []}
          />
        )}
      </div>
      <UserDrawer
        isOpen={drawerOpen}
        onUserUpdate={(user) => {
          setUsers((prevUsers) => {
            if (!prevUsers) return prevUsers;
            const index = prevUsers.findIndex((u) => u.id === user.id);
            if (index === -1) {
              return [user, ...prevUsers];
            }
            const newUsers = [...prevUsers];
            newUsers[index] = user;
            return newUsers;
          });
        }}
        onUserCreate={(user) => {
          setUsers((prevUsers) => {
            if (!prevUsers) return prevUsers;
            return [...prevUsers, user];
          });
        }}
        onUserDelete={(userId) => {
          setUsers((prevUsers) => {
            if (!prevUsers) return prevUsers;
            return prevUsers.filter((u) => u.id !== userId);
          });
        }}
        onClose={() => setDrawerOpen(false)}
        user={userToEdit}
      />
    </>
  );
}

export default UsersPage;
