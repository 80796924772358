import React from "react";
import CustomDropdown from "../../shared/components/general/custom-dropdown";
import CustomButton from "../../shared/components/general/custom-button";
import { useAuthContext } from "../../context/AuthContext";

type Props = {
  setFileDialogOpen: (value: boolean) => void;
  setLinkDialogOpen: (value: boolean) => void;
  setOpenDeleteDialog: (value: boolean) => void;
};

function SectionActions({
  setFileDialogOpen,
  setLinkDialogOpen,
  setOpenDeleteDialog,
}: Props) {
  const { user } = useAuthContext();

  function getMenuItems() {
    const items = [];

    if (user?.permission.canEditSectionFiles) {
      items.push({
        title: "Archivo",
        onClick: () => setFileDialogOpen(true),
      });
    }

    if (user?.permission.canEditSectionLinks) {
      items.push({
        title: "Link",
        onClick: () => setLinkDialogOpen(true),
      });
    }
    return items;
  }

  const menuItems = getMenuItems();

  return (
    <>
      {menuItems.length > 0 && (
        <CustomDropdown
          containerClassName="w-fit ml-auto"
          title="Agregar"
          menuClassName="bg-gray-700 text-white hover:bg-gray-600 py-1.5"
          menuItems={menuItems}
        />
      )}
      {user?.permission.canDeleteSection && (
        <CustomButton
          title="Eliminar"
          className="py-1 text-sm px-5 bg-pantone ml-2 hover:bg-red-900"
          onClick={() => setOpenDeleteDialog(true)}
        />
      )}
    </>
  );
}

export default SectionActions;
