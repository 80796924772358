import React from "react";
import { Section, SectionLink } from "../../shared/types/section";
import CustomDropdown from "../../shared/components/general/custom-dropdown";
import apiService from "../../services/api-service";
import toast from "react-hot-toast";
import { useAuthContext } from "../../context/AuthContext";

type Props = {
  section: Section;
  onLinkDelete: (sectionLink: SectionLink) => void;
  onLinkEditClicked: (link: SectionLink) => void;
};

type LinkProps = {
  link: SectionLink;
};

function Link({ link }: LinkProps) {
  return (
    <div className="flex items-center justify-between  ">
      <a
        href={link.url}
        className="font-medium text-blue-600 underline text-base"
        target="_blank"
        rel="noreferrer"
      >
        {link.linkTitle}
      </a>
    </div>
  );
}

function SectionLinks({ section, onLinkDelete, onLinkEditClicked }: Props) {
  const links = section.links || [];
  const { user } = useAuthContext();

  async function handleDelete(link: SectionLink) {
    onLinkDelete(link);
    const { error } = await apiService.delete(
      `sections/${section.id}/links/${link.id}`
    );

    if (error) {
      toast.error("Error al eliminar link");
      return;
    }
  }

  if (links.length === 0) {
    return <></>;
  }

  return (
    <div className="flex flex-col items-start justify-start 0 w-full space-y-3 mt-14 rounded-md ">
      <p className="text-lg ">Informacion Relacionada:</p>

      <div className="flex w-full  items-center justify-start">
        {links.map((link) => (
          <div className="flex items-center mr-10 space-x-2" key={link.id}>
            <Link key={link.id} link={link} />
            {user?.permission.canEditSectionLinks && (
              <CustomDropdown
                withThreeDots
                title=""
                menuItems={[
                  {
                    title: "Editar",
                    onClick: () => {
                      onLinkEditClicked(link);
                    },
                  },
                  {
                    title: "Eliminar",
                    onClick: () => {
                      handleDelete(link);
                    },
                  },
                ]}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SectionLinks;
