import React from "react";
import { Disclosure } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import UsersPage from "../../users/users-page";

enum Tab {
  USERS = "usuarios",
  PAGES = "paginas",
}

const navigation = [
  { name: Tab.USERS, href: "#", current: true },
  { name: Tab.PAGES, href: "#", current: false },
];

function AdminLayout() {
  const [currentTab, setCurrentTab] = React.useState(Tab.USERS);

  function Content() {
    if (currentTab === Tab.USERS) {
      return <UsersPage />;
    }
    return <></>;
  }

  return (
    <div className="min-h-full overflow-hidden">
      <Disclosure as="nav" className="border-b border-gray-200 bg-white">
        <div className="mx-auto max-w-7xl ">
          <div className="flex h-16 justify-between">
            <div className="flex">
              <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                {navigation.map((item) => (
                  <button
                    key={item.name}
                    aria-current={item.current ? "page" : undefined}
                    className={twMerge(
                      item.name === currentTab
                        ? "border-pantone text-gray-900"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium"
                    )}
                    onClick={() => setCurrentTab(item.name as Tab)}
                  >
                    {item.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Disclosure>

      <div className="py-10 max-h-[500px] overflow-y-auto">
        <main className="overflow-y-auto">
          <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
            <Content />
          </div>
        </main>
      </div>
    </div>
  );
}

export default AdminLayout;
