import { PlusIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import CustomInput from "../../shared/components/general/custom-input";
import CustomButton from "../../shared/components/general/custom-button";
import { Section, SectionImage } from "../../shared/types/section";
import apiService from "../../services/api-service";
import toast from "react-hot-toast";
import { RichTextEditor } from "../../shared/components/general/rich-text";
import { twMerge } from "tailwind-merge";

type Props = {
  isEmpty: boolean;
  sectionCounts: number;
  onSectionCreated: (section: Section) => void;
  pageName: string;
};

export default function NewSectionBox({
  isEmpty,
  onSectionCreated,
  sectionCounts,
  pageName,
}: Props) {
  const [isCreating, setIsCreating] = useState(false);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [newCreatedSection, setNewCreatedSection] = useState<Section | null>();
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loading, setLoading] = useState(false);

  if (!isCreating) {
    return <IdleMode />;
  }

  async function onImageUpload(file: File) {
    const { data, error } = await apiService.uploadFile<SectionImage>(
      `sections/${newCreatedSection!.id}/images`,
      file
    );

    if (error) {
      toast.error("Error subiendo la imagen");
      return "";
    }

    return data?.presignedUrl!;
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!title || !content) {
      toast.error("Debes llenar todos los campos");
      return;
    }
    setLoading(true);

    const { data, error } = await apiService.put<Section>(
      `sections/${newCreatedSection?.id}`,
      {
        pageName: pageName,
        title,
        content,
      }
    );

    if (error) {
      setLoading(false);
      toast.error("Se produjo un error, intentalo de nuevo");
      return;
    }
    setLoading(false);
    onSectionCreated(data!);
    setIsCreating(false);
  }

  return (
    <form className="w-full flex flex-col space-y-5" onSubmit={handleSubmit}>
      <p className="text-2xl">Crea una nueva seccion</p>
      <CustomInput
        placeholder="Titulo"
        required
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <RichTextEditor
        initialContent={content}
        onImageUpload={onImageUpload}
        onContentChanged={(s) => setContent(s)}
      />
      <div className="flex self-end space-x-2">
        <CustomButton
          title="Cancelar"
          onClick={async () => {
            setIsCreating(false);
            await apiService.delete(`sections/${newCreatedSection!.id}`);
            setNewCreatedSection(null);
            setContent("");
          }}
          filled={false}
        />
        <CustomButton title="Crear" submit loading={loading} />
      </div>
    </form>
  );

  function IdleMode() {
    return (
      <div className="text-center">
        <svg
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          aria-hidden="true"
          className="mx-auto h-12 w-12 text-gray-400"
        >
          <path
            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            strokeWidth={2}
            vectorEffect="non-scaling-stroke"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <h3 className="mt-2 text-sm font-semibold text-gray-900">
          {isEmpty ? "Sin secciones" : "Crea una seccion"}
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          {isEmpty ? "Empieza creando una seccion" : "Añade una seccion"}
        </p>
        <div className="mt-6">
          <button
            type="button"
            onClick={async () => {
              setLoadingCreate(true);
              const { data, error } = await apiService.post<Section>(
                "sections",
                {
                  pageName: pageName,
                  title: "-",
                  content: "-",
                }
              );

              if (error) {
                setLoadingCreate(false);
                toast.error("Se produjo un error, intentalo de nuevo");
                return;
              }
              setLoadingCreate(false);
              setNewCreatedSection(data!);
              setIsCreating(true);
            }}
            className={twMerge(
              "inline-flex items-center rounded-md bg-gray-700 hover:bg-gray-600 focus-visible:outline-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
              loadingCreate && "animate-pulse cursor-progress"
            )}
            disabled={loadingCreate}
          >
            <PlusIcon aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5" />
            Nueva Seccion
          </button>
        </div>
      </div>
    );
  }
}
