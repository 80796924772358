import { Route, Routes } from "react-router-dom";
import LoginPage from "../auth/login-page";
import AuthValidator from "./AuthValidator";
import BasePage from "../wiki/components/BasePage";
import ActivateAccount from "../auth/activate-account";
import ForgotPassword from "../auth/forgot-password";
import SearchPage from "../search/pages/search-page";
import UsersPage from "../users/users-page";
import MyAccountPage from "../users/my-account-page";
import AdminPage from "../admin/admin-page";

interface RouteI {
  path: string;
  element: React.ReactNode;
}

const publicRoutes = [
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/activar-cuenta",
    element: <ActivateAccount />,
  },
  {
    path: "/olvide-clave",
    element: <ForgotPassword />,
  },
];

const privateRoutes = [
  {
    path: "/home",
    element: <BasePage page="home" />,
  },
  {
    path: "/information-technology",
    element: <BasePage page="information-technology" />,
  },
  {
    path: "/arquitectura-empresarial",
    element: <BasePage page="arquitectura-empresarial" />,
  },
  {
    path: "/busqueda",
    element: <SearchPage />,
  },
  {
    path: "/usuarios",
    element: <UsersPage />,
  },
  {
    path: "/mi-cuenta",
    element: <MyAccountPage />,
  },
  {
    path: "/administracion",
    element: <AdminPage />,
  },
];

export default function Router() {
  return (
    <Routes>
      {/* Public Routes */}
      {publicRoutes.map((route: RouteI) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}

      {/* Private Routes */}
      {privateRoutes.map((route: RouteI) => (
        <Route key={route.path} element={<AuthValidator href={route.path} />}>
          <Route path={route.path} element={route.element} />
        </Route>
      ))}
    </Routes>
  );
}
