import {
  Disclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import SearchBar from "../general/seach-bar";

const navigation = [
  { name: "Inicio", path: "/home" },
  {
    name: "Tecnologia de la información",
    path: "/information-technology",
  },
  { name: "Arquitectura Empresarial", path: "/arquitectura-empresarial" },
];
const userNavigation = [
  { name: "Mi Cuenta", href: "/mi-cuenta" },
  { name: "Ayuda", href: "#" },
  { name: "Contacto", href: "#" },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export default function DasboardTopbar() {
  const { user } = useAuthContext();
  const fullName = `${user?.name} ${user?.lastName}`;

  function getGreeting() {
    const date = new Date();

    if (date.getHours() >= 0 && date.getHours() < 12) {
      return "Buenos días";
    }
    if (date.getHours() >= 12 && date.getHours() < 18) {
      return "Buenas tardes";
    }
    if (date.getHours() >= 18 && date.getHours() < 24) {
      return "Buenas noches";
    }
  }

  return (
    <Disclosure as="header" className=" shadow-lg">
      <div className="mx-auto max-w-7xl px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
        <div className="relative flex h-16 justify-between items-center">
          <div className="">
            <p className="text-base font-medium">{getGreeting()}</p>
            <p className="text-sm ">{fullName}</p>
          </div>
          <SearchBar />

          {/* Profile dropdown */}
          <Menu as="div" className="">
            <div>
              <MenuButton className="relative flex max-w-xs items-center rounded-full text-sm focus:outline-none focus:ring-2 hover:ring-pantone focus:ring-offset-2">
                <img
                  alt=""
                  src={
                    "https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"
                  }
                  className="h-8 w-8 rounded-full"
                />
              </MenuButton>
            </div>
            <MenuItems
              transition
              className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              {userNavigation.map((item) => (
                <MenuItem key={item.name}>
                  <NavLink
                    to={item.href}
                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100"
                  >
                    {item.name}
                  </NavLink>
                </MenuItem>
              ))}
            </MenuItems>
          </Menu>
        </div>
        <nav
          aria-label="Global"
          className="hidden lg:flex lg:space-x-8 lg:py-2"
        >
          {navigation.map((item) => (
            <NavLink
              key={item.name}
              to={item.path}
              className={classNames(
                window.location.pathname.includes(item.path)
                  ? "bg-gray-200 text-gray-900"
                  : "text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                "inline-flex items-center rounded-md px-3 py-2 text-sm font-medium"
              )}
            >
              {item.name}
            </NavLink>
          ))}
        </nav>
      </div>
    </Disclosure>
  );
}
