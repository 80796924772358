import React, { useEffect, useState } from "react";
import { useSections } from "../wiki/hooks/use-sections";
import { Section } from "../shared/types/section";
import { useLocation } from "react-router-dom";

interface IBasePageContext {
  sections: Section[] | undefined | null;
  error: boolean;
  loading: boolean;
  onSectionCreated: (section: Section) => void;
  onSectionDeleted: (sectionId: number) => void;
}

const defaultContext: IBasePageContext = {
  sections: null,
  error: false,
  loading: true,
  onSectionCreated: () => {},
  onSectionDeleted: () => {},
};

export const BasePageContext =
  React.createContext<IBasePageContext>(defaultContext);

export default function BasePageProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const location = useLocation();
  const [page, setPage] = useState<string>(location.pathname.split("/")[1]);

  const { sections, error, loading } = useSections(page);
  const [sectionsOnPage, setSectionsOnPage] = useState<
    Section[] | null | undefined
  >(sections);

  function onSectionCreated(section: Section) {
    setSectionsOnPage((prev) => {
      if (!prev) return [section];
      return [...prev, section];
    });
  }

  function onSectionDeleted(sectionId: number) {
    setSectionsOnPage((prev) => {
      if (!prev) return null;
      return prev.filter((section) => section.id !== sectionId);
    });
  }

  useEffect(() => {
    setPage(location.pathname.split("/")[1]);
  }, [location.pathname]);

  useEffect(() => {
    setSectionsOnPage(sections);
  }, [page, sections]);

  useEffect(() => {
    if (sectionsOnPage && location.hash) {
      const sectionId = location.hash.replace("#", "");
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [sectionsOnPage, location.hash]);

  return (
    <BasePageContext.Provider
      value={{
        sections: sectionsOnPage,
        error,
        loading,
        onSectionCreated,
        onSectionDeleted,
      }}
    >
      {children}
    </BasePageContext.Provider>
  );
}

export const useBasePageContext = () => {
  return React.useContext(BasePageContext);
};
