import React from "react";
import { twMerge } from "tailwind-merge";

type CustomInputProps = {
  className?: string | undefined;
  placeholder?: string;
  type?: string;
  error?: string | undefined;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  disabled?: boolean;
  value?: string | number | readonly string[] | undefined;
};

function CustomInput({
  className,
  placeholder,
  value,
  type,
  required = false,
  onChange,
  disabled,
  error,
}: CustomInputProps) {
  return (
    <div className={twMerge(className)}>
      <input
        type={type}
        required={required}
        value={value}
        className={twMerge(
          "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-400   sm:text-sm sm:leading-6 p-2",
          error ? "ring-red-500 mb-1.5" : "ring-gray-600"
        )}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
      />
      {error && <p className="text-red-500 text-xs">{error}</p>}
    </div>
  );
}

export default CustomInput;
