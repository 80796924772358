import React from "react";

type Props = {
  children: React.ReactNode;
};

function AuthLayout({ children }: Props) {
  return (
    <div className=" w-full h-[100dvh] border flex items-center justify-between">
      <img
        src="/images/login.jpg"
        alt="logo"
        className="w-1/2 h-full object-cover"
      />
      <div className="flex flex-col   w-full items-center justify-center flex-1">
        {children}
      </div>
    </div>
  );
}

export default AuthLayout;
