import React from "react";
import AuthLayout from "./components/auth-layout";
import CustomInput from "../shared/components/general/custom-input";
import CustomButton from "../shared/components/general/custom-button";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../services/api-service";
import { LoginResponse } from "../shared/types/auth";
import { useAuthContext } from "../context/AuthContext";
import { saveAuthCookies } from "../shared/utils/auth";
import User from "../shared/types/user";
import toast from "react-hot-toast";
import { verifyPasswords } from "./utils/auth-utlis";

function ActivateAccount() {
  const [newPwd, setNewPwd] = React.useState("");
  const [confirm, setConfirm] = React.useState("");
  const [old, setOld] = React.useState("");
  const [error, setError] = React.useState("");
  const [oldError, setOldError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { setUser } = useAuthContext();
  const navigate = useNavigate();

  async function onSmubmit(e: React.FormEvent) {
    e.preventDefault();
    const pwdError = verifyPasswords(newPwd, confirm);
    if (pwdError !== null) {
      setError(pwdError);
      return;
    }
    setLoading(true);

    // Call the API to update the password
    const { error, data } = await apiService.post<LoginResponse>(
      "auth/changePassword",
      {
        email: localStorage.getItem("email"),
        oldPassword: old,
        newPassword: newPwd,
      }
    );

    if (error) {
      setOldError("Clave incorrecta");
      setLoading(false);
      return;
    }
    setOldError("");

    saveAuthCookies(data!);
    apiService.authToken = data?.accessToken.jwtToken;
    const { error: userError, data: user } = await apiService.get<User>(
      `auth/me`
    );
    if (userError) {
      setLoading(false);
      navigate("/");
      toast.error("Se produjo un error, intentalo de nuevo");
      return;
    }
    navigate("/home");
    setLoading(false);
    setUser(user);
  }

  return (
    <AuthLayout>
      <form
        className="flex flex-col   w-full items-center justify-center flex-1"
        onSubmit={onSmubmit}
      >
        <h1 className="text-4xl mb-10">Bienvenido</h1>
        <p className="text-lg mb-10">
          Por favor, cambia tu clave para continuar.
        </p>
        <CustomInput
          type="password"
          required
          error={oldError}
          value={old}
          onChange={(e) => setOld(e.target.value)}
          placeholder="Clave Anterior"
          className="w-60 mb-4"
        />
        <CustomInput
          type="password"
          value={newPwd}
          required
          onChange={(e) => setNewPwd(e.target.value)}
          placeholder="Nueva Clave"
          className="w-60 mb-4"
        />
        <CustomInput
          type="password"
          value={confirm}
          required
          onChange={(e) => setConfirm(e.target.value)}
          error={error}
          placeholder="Confirmar Clave"
          className="w-60 mb-4"
        />
        <CustomButton
          className="w-60 mb-4"
          title="Activar Cuenta"
          submit
          loading={loading}
        />
        <Link to="/" className="underline text-sm text-gray-600 mb-4 ">
          Regresar a inicio
        </Link>
      </form>
    </AuthLayout>
  );
}

export default ActivateAccount;
