import React, { useEffect } from "react";
import CustomDialog from "../../shared/components/general/custom-dialog";
import { Section, SectionLink } from "../../shared/types/section";
import CustomInput from "../../shared/components/general/custom-input";
import CustomButton from "../../shared/components/general/custom-button";
import apiService from "../../services/api-service";
import toast from "react-hot-toast";
import { twMerge } from "tailwind-merge";
import KeywordInput from "./KeyWordInput";

type Props = {
  section: Section;
  open: boolean;
  setOpen: (open: boolean) => void;
  onLinkCreated: (link: SectionLink) => void;
  onClose: () => void;
  onLinkUpdated: (link: SectionLink) => void;
  link?: SectionLink | null | undefined;
};

function SectionLinkDialog({
  section,
  open,
  setOpen,
  onLinkCreated,
  onClose,
  onLinkUpdated,
  link,
}: Props) {
  const editMode = link !== null && link !== undefined;
  const [title, setTitle] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [keywords, setKeywords] = React.useState<string[]>([]);

  useEffect(() => {
    if (link) {
      setUrl(link.url || "");
      setTitle(link.linkTitle || "");
      setKeywords(link.keywords || []);
    } else {
      setUrl("");
      setKeywords([]);
      setTitle("");
    }
  }, [link]);

  const isValidURL = (text: string): boolean => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlRegex.test(text);
  };

  async function createLink(e: any) {
    e.preventDefault();

    if (!isValidURL(url)) {
      setError("URL no válida");
      return;
    }
    setError("");

    setLoading(true);
    const { data, error } = await apiService.post<SectionLink>(
      `sections/${section.id}/links`,
      {
        linkTitle: title,
        url,
        keywords,
      }
    );

    if (error) {
      toast.error("Error al crear link");
      setLoading(false);
      return;
    }

    closeDialog();
    onLinkCreated(data!);
    setTitle("");
    setKeywords([]);
    setUrl("");
  }

  async function updateLink() {
    if (!isValidURL(url)) {
      setError("URL no válida");
      return;
    }
    setError("");

    setLoading(true);
    const { data, error } = await apiService.put<SectionLink>(
      `sections/${section.id}/links/${link?.id}`,
      {
        linkTitle: title,
        url,
        keywords,
      }
    );

    if (error) {
      toast.error("Error al actualizar link");
      setLoading(false);
      return;
    }

    closeDialog();
    onLinkUpdated(data!);
    setTitle("");
    setUrl("");
  }

  function closeDialog() {
    setOpen(false);
    setLoading(false);
    setError("");
    onClose();
  }

  return (
    <CustomDialog
      open={open}
      setOpen={setOpen}
      dialogTitle="Nuevo Link"
      onClose={closeDialog}
    >
      <form className="space-y-4 " onSubmit={createLink}>
        <CustomInput
          placeholder="Titulo"
          value={title}
          required
          onChange={(e) => setTitle(e.target.value)}
        />
        <CustomInput
          placeholder="URL"
          error={error}
          required
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <KeywordInput
          initialKeywords={link?.keywords || keywords}
          onKeywordsChange={setKeywords}
        />
        {editMode ? (
          <CustomButton
            title="Actualizar"
            className={twMerge("w-full", loading ? "cursor-progress" : "")}
            onClick={updateLink}
          />
        ) : (
          <CustomButton
            title="Agregar"
            className={twMerge("w-full", loading ? "cursor-progress" : "")}
            submit
          />
        )}
      </form>
    </CustomDialog>
  );
}

export default SectionLinkDialog;
