import { useState } from "react";
import DashboardTopbar from "./dashboard-topbar";
import NavBar from "./nav-bar";
import { twMerge } from "tailwind-merge";

type Props = {
  children: React.ReactNode;
  showSidebar?: boolean;
};

export default function DashboardLayout({
  children,
  showSidebar = true,
}: Props) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(showSidebar);

  return (
    <div className="overflow-hidden h-screen flex">
      {/* Sidebar */}

      {showSidebar && (
        <NavBar open={isSidebarOpen} setOpen={setIsSidebarOpen} />
      )}

      {/* Main Content */}
      <div
        className={twMerge(
          "flex-1 flex flex-col transition-[padding-left] duration-300",
          isSidebarOpen && showSidebar ? "lg:pl-64" : "lg:pl-0"
        )}
      >
        {/* Sticky top bar */}
        <div className="sticky top-0 z-10">
          <DashboardTopbar />
        </div>

        <main className="overflow-y-auto flex-1  ">
          <div
            className={twMerge(
              "mx-auto w-full",
              !isSidebarOpen ? "px-24" : "px-6"
            )}
          >
            {children}
          </div>
        </main>
      </div>
    </div>
  );
}
