import { useState, useEffect } from "react";
import { Section } from "../../shared/types/section";
import apiService from "../../services/api-service";

interface State {
  sections?: Section[] | null;
  loading: boolean;
  error: boolean;
}

const initalState = {
  sections: [],
  loading: true,
  error: false,
};

const omitedPages = [
  "busqueda",
  "",
  "olvide-clave",
  "usuarios",
  "mi-cuenta",
  "administracion",
];

export const useSections = (page: string) => {
  const [state, setState] = useState<State>(initalState);

  async function getSections(page: string) {
    if (omitedPages.includes(page)) return;

    const { data, error } = await apiService.get<Section[]>("sections", {
      pageName: page,
    });

    setState({
      loading: false,
      sections: error ? null : data,
      error: error,
    });
  }

  useEffect(() => {
    setState(initalState);
    getSections(page);
  }, [page]);

  return state;
};
