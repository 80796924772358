import React, { useEffect, useMemo, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Quill from "quill";
import ImageUploader from "quill-image-uploader";
import "quill-image-uploader/dist/quill.imageUploader.min.css";

Quill.register("modules/imageUploader", ImageUploader);

type Props = {
  initialContent: string;
  onContentChanged: (content: string) => void;
  onImageUpload?: (file: File) => Promise<string>;
};

export function RichTextEditor({
  initialContent,
  onContentChanged,
  onImageUpload,
}: Props) {
  const [text, setText] = useState(initialContent);

  useEffect(() => {
    setText(initialContent);
  }, [initialContent]);

  const modules = useMemo(
    () => ({
      toolbar: [
        [{ header: [1, 2, 3, false] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ script: "sub" }, { script: "super" }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ["link", "image"],
        ["clean"],
      ],
      imageUploader: {
        upload: (file: File) => {
          return new Promise(async (resolve, reject) => {
            try {
              const result = await onImageUpload?.(file);
              if (result) {
                resolve(result);
              } else {
                reject("Error uploading image");
              }
            } catch (error) {
              reject("Error uploading image");
            }
          });
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <ReactQuill
      theme="snow"
      value={text}
      onChange={(s) => {
        setText(s);
        onContentChanged(s);
      }}
      modules={modules}
    />
  );
}
